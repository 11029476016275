import React from 'react';
import { Loader } from '@refrens/disco';
import { RefrensWhite } from '@refrens/disco/icons';
import styled from 'styled-components';

const PageLoaderWrapper = styled.div`
  position: fixed;
  top: 45%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sizes-xsmall);
  @keyframes fade-in-scale {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 100;
      transform: scale(1);
    }
  }

  &[data-animate='true'] {
    animation: fade-in-scale 0.5s;
  }
  &[data-position='absolute'] {
    position: absolute;
  }
`;

const PageLoadingFooterLogoWrapper = styled.div`
  position: fixed;
  bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @keyframes fade-in-footer-logo {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }

  &[data-animate='true'] {
    animation: fade-in-footer-logo 0.5s;
  }
  &[data-position='absolute'] {
    position: absolute;
  }
`;

/**
 * Render a page loader with optional animation and footer logo.
 * This would cover the available screen real estate until the page is ready to be rendered.
 */
const PageLoader = (props) => {
  const { animate = false, withFooterLogo = false, error = false, position = 'fixed' } = props;

  return (
    <>
      <PageLoaderWrapper data-position={position} data-animate={!!animate}>
        <Loader
          size={48}
          color={error ? 'var(--color-red-500)' : 'var(--color-primary-500)'}
          position='static'
        />
      </PageLoaderWrapper>
      {!!withFooterLogo && (
        <PageLoadingFooterLogoWrapper data-position={position} data-animate={!!animate}>
          <RefrensWhite size={28} color='var(--color-neutral-300)' />
        </PageLoadingFooterLogoWrapper>
      )}
    </>
  );
};

export default PageLoader;
