import React from 'react';
import { string, object, node, func, oneOfType, number } from 'prop-types';
import styled from 'styled-components';
import Nbsp from 'react-nbsp';

import { Link } from '@/router';

import Tag from './Tag';

const A = styled(({ tag = 'a', children, ...props }) => React.createElement(tag, props, children))`
  position: relative;
  border: 0;
  outline: 0;
  background: none;
  width: 100%;
  text-align: left;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  &:disabled {
    cursor: not-allowed;
  }
  > svg {
    vertical-align: middle;
  }
`;

A.propTypes = {
  'data-ga-on': string.isRequired,
  'data-ga-event-category': string.isRequired,
  'data-ga-event-action': string.isRequired,
};

const MenuSpan = styled.span`
  vertical-align: middle;
  padding-left: ${(props) => props.theme.lydia.spacing.padding};
  padding-left: 10px;
  display: inline-block;
  /* width: 100%; */
  /* margin-right: -34px; */
  flex-grow: 1;
`;

const EmA = styled(A)`
  && {
    && {
      font-weight: bold;
      color: ${(props) => props.theme.lydia.colors.secondary};
      > svg {
        fill: ${(props) => props.theme.lydia.colors.secondary};
      }
    }
  }
`;

const Badge = styled(Tag)`
  display: inline;
`;

function MenuButton(props) {
  const {
    icon,
    Icon,
    iconSize,
    RightIcon,
    label,
    tag,
    onClick,
    highlight,
    target,
    title,
    download,
    rel,
    disabled = false,
    route,
    params = {},
    prefetch = false,
    href,
    ...linkProps
  } = props;
  const leftIconSize = iconSize || 18;
  const Anchor = highlight ? EmA : A;
  if (onClick) {
    return (
      <Anchor tag='button' onClick={onClick} disabled={disabled} {...linkProps}>
        {icon || (Icon && <Icon size={leftIconSize} />)}
        <MenuSpan>
          {label}
          {!!tag && (
            <>
              <Nbsp />
              <Badge color='danger'>{tag}</Badge>
            </>
          )}
        </MenuSpan>
        {RightIcon && <RightIcon size={18} />}
      </Anchor>
    );
  }
  if (href) {
    return (
      <Anchor
        target={target}
        title={title}
        download={download}
        rel={rel}
        disabled={disabled}
        href={href}
        {...linkProps}
      >
        {icon || (Icon && <Icon size={leftIconSize} />)}
        <MenuSpan>
          {label}
          {!!tag && (
            <>
              <Nbsp />
              <Badge color='danger'>{tag}</Badge>
            </>
          )}
        </MenuSpan>
        {RightIcon && <RightIcon size={18} />}
      </Anchor>
    );
  }
  return (
    <Link route={route} params={params} passHref prefetch={prefetch}>
      <Anchor
        target={target}
        title={title}
        download={download}
        rel={rel}
        disabled={disabled}
        {...linkProps}
      >
        {icon || (Icon && <Icon size={leftIconSize} />)}
        <MenuSpan>
          {label}
          {!!tag && (
            <>
              <Nbsp />
              <Badge color='danger'>{tag}</Badge>
            </>
          )}
        </MenuSpan>
        {RightIcon && <RightIcon size={18} />}
      </Anchor>
    </Link>
  );
}

MenuButton.propTypes = {
  Icon: oneOfType([node, func, object]),
  label: string,
  tag: oneOfType([string, number]),
};

export default MenuButton;
