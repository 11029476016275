import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Toast } from '@refrens/disco';
import { Warning } from '@refrens/disco/icons';
import styled from 'styled-components';

const StyledToastMessage = styled.div`
  && {
    color: var(--color-neutral-800);
  }
`;

class NetworkDetector extends Component {
  componentDidMount() {
    this.handleConnectionChange();
    this.registerWebPing();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentDidUpdate(prevProps) {
    const { hideNetworkDetector = false, store } = this.props;
    const { showNetworkNotification } = store;
    if (
      prevProps.store.showNetworkNotification !== showNetworkNotification ||
      prevProps.hideNetworkDetector !== hideNetworkDetector
    ) {
      if (showNetworkNotification) {
        this.showNotification();
      } else {
        this.hideNotification();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
    if (window.webPing) {
      clearInterval(window.webPing);
      window.webPing = null;
    }
  }

  showNotification = () => {
    const { hideNetworkDetector = false } = this.props;
    if (!hideNetworkDetector) {
      Toast({
        message: (
          <StyledToastMessage>
            Oops, It seems there is some problem with your internet connectivity.
          </StyledToastMessage>
        ),
        position: 'bottom-right',
        toastId: 'refrens-network',
        customIcon: <Warning color='var(--color-yellow-500)' />,
      });
    }
  };

  hideNotification = () => {
    const { hideNetworkDetector = false } = this.props;
    if (!hideNetworkDetector) {
      Toast.dismiss('refrens-network');
    }
  };

  registerWebPing = () => {
    if (window.webPing) {
      clearInterval(window.webPing);
      window.webPing = null;
    }

    window.webPing = setInterval(() => {
      fetch('https://www.google.com/humans.txt', { mode: 'no-cors', method: 'HEAD' })
        .then(() => {
          this.props.store.showNetworkNotification = false;
        })
        .catch(() => {
          this.props.store.showNetworkNotification = true;
        });
    }, 30000);
  };

  handleConnectionChange = () => {
    this.props.store.showNetworkNotification = !navigator.onLine;
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <></>;
  }
}

export default inject('store')(observer(NetworkDetector));
