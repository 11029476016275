import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 54px 1fr;
  color: var(--color-black-100);
`;

export const TextWrapper = styled.div`
  display: inline-block;
  line-height: var(--sizes-small);
  margin-left: var(--sizes-small);
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 3.375rem;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--sizes-small);
`;

export const PrimaryHeaderText = styled.div`
  display: inline-flex;
  max-width: 18.75rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: var(--sizes-small);
`;

export const ProfileWrapper = styled.div`
  display: flex;
  color: var(--color-black-100);
`;

export const ProfileAvatarWrapper = styled.div`
  display: flex;
`;
