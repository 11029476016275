// landing pages to open Clients&Leads sub menu by default
const ClientLeadsMenuLP = [
  '/lead-management-software',
  '/rf-sales-crm',
  '/rf-sales-pipeline-management-software',
  '/rf-quote-software-with-lead-management-system',
];

const NoSubMenuRoutes = ['Dashboard', 'UserFeed', 'Settings', 'UserProfile', 'BusinessGreetings'];

const getSidebarDefaultSubOpenMenu = ({ pageRoute, landingPage, visitedMenuItems = [] }) => {
  // check if visitedMenuItems has anything other than NoSubMenuRoutes
  const visitedMenuItemsFiltered = (visitedMenuItems || [])?.filter(
    (item) => !NoSubMenuRoutes.includes(item),
  );
  if (visitedMenuItemsFiltered.length > 0) {
    // we don't want to set a default if user has already visited a menu item
    return undefined;
  }
  if (!landingPage) {
    return 'ACCOUNTING';
  }
  if (NoSubMenuRoutes.includes(pageRoute?.name)) {
    try {
      const lpPath = new URL(landingPage)?.pathname?.toLowerCase();
      if (lpPath) {
        // loose pattern match for landing pages
        if (ClientLeadsMenuLP.some((item) => lpPath.includes(item))) {
          return 'CLIENTS_LEADS';
        }
      }
      // default all other path patterns to Accounting submenu
      return 'ACCOUNTING';
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
};

export default getSidebarDefaultSubOpenMenu;
