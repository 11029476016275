/* eslint import/no-commonjs: [0] */
const theme = {
  fonts: {
    body: '"Inter", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;',
  },
  colors: {
    primary: 'var(--color-primary-500)',
    secondary: 'var(--color-secondary-500)',
    danger: 'var(--color-red-400)',
    success: 'var(--color-green-400)',
    warning: 'var(--color-yellow-400)',
    info: 'var(--color-blue-400)',
    gray: 'var(--color-neutral-900)',
    lightGray: 'var(--color-neutral-500)',
    disabled: 'var(--color-neutral-400)',
    devider: 'var(--color-neutral-200)',
    progress: 'var(--color-red-500)',
    offWhite: 'var(--color-neutral-200)',
    text: {
      light: 'var(--color-white-100)',
      // dark: 'var(--color-neutral-900)',
      dark: 'var(--color-neutral-900)',
      secondary: 'var(--color-neutral-500)',
      lightSecondary: 'var(--color-white-70)',
      home: {
        copy: 'var(--color-neutral-500)',
        heading: 'var(--color-neutral-900)',
      },
    },
    bg: {
      body: 'var(--color-neutral-50)',
      content: 'var(--color-white-100)',
      input: 'var(--color-neutral-50)',
    },
    social: {
      instagram: 'var(--color-secondary-500)',
      facebook: 'var(--color-blue-700)',
      twitter: 'var(--color-blue-400)',
      linkedin: 'var(--color-blue-600)',
      website: 'var(--color-primary-500)',
      whatsapp: 'var(--color-green-400)',
      gmail: 'var(--color-red-400)',
    },
    scale: [
      'var(--color-primary-600)',
      'var(--color-primary-400)',
      'var(--color-green-600)',
      'var(--color-neutral-900)',
    ],
  },
  sizes: {
    header: {
      height: '56px',
    },
    footer: {
      height: '56px',
    },
    main: {
      width: '1000px',
      paddingTop: '56px',
    },
    largeMain: {
      width: '1200px',
    },
    aside: {
      width: '256px',
    },
  },
  spacing: {
    padding: '10px',
  },
  screen: {
    gte: {
      xxs: 'min-width: 411px',
      xs: 'min-width: 568px',
      s2: 'min-width: 660px',
      sm: 'min-width: 768px',
      md: 'min-width: 992px',
      lg: 'min-width: 1200px',
      xl: 'min-width: 1440px',
    },
    lte: {
      xxs: 'max-width : 411px',
      xs: 'max-width : 568px',
      s2: 'max-width: 660px',
      sm: 'max-width: 768px',
      md: 'max-width: 992px',
      lg: 'max-width: 1200px',
      xl: 'max-width: 1440px',
    },
  },
  breakpoints: {
    aside: 'md',
    inbox: 'xs',
  },
};

module.exports = theme;
