import React from 'react';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { Alert, Toast, Modal, Form as DiscoForm, Typography, Button } from '@refrens/disco';
import { observable, action, when, makeObservable } from 'mobx';
import Nbsp from 'react-nbsp';
import styled from 'styled-components';
import { trackEvent, RefrensBasicApi, Recaptcha } from '@refrens/jupiter';

import { Auth } from '@/schemas/auth';

import { Row, Col } from '@/components/styles/LydiaComponents';

const AlertText = styled(Typography)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--sizes-xsmall);
`;

const FormBodyWrapper = styled(Col)`
  gap: 2rem;
`;

const BodyWrapper = styled(Col)`
  gap: var(--sizes-regular);
`;

const ButtonContainer = styled(Row)`
  && {
    gap: var(--sizes-regular);
    align-self: flex-start;
    flex-wrap: wrap;
  }
`;

class EmailVerificatonPrompt extends React.Component {
  showEmailPopup = false;

  user = null;

  allowEmailChange = false;

  recaptchaResponse = undefined;

  constructor(props) {
    super(props);

    makeObservable(this, {
      showEmailPopup: observable,
      user: observable,
      allowEmailChange: observable,
      updateEmail: action,
    });

    this.store = props.store;

    this.schema = Auth;
    this.user = observable(this.schema.default());
    this.showEmailPopup = !!props.showEmailPopup;

    this.recaptchaRef = React.createRef();
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.user.email = this.store.auth && this.store.auth.email;
  }

  componentDidUpdate(preProps) {
    const { showEmailPopup } = this.props;
    if (preProps.showEmailPopup !== showEmailPopup) {
      this.showEmailPopup = showEmailPopup;
    }
  }

  resendVerification = async () => {
    try {
      const res = await this.store.api.post(
        'authentication/link',
        {
          email: this.store.auth.email,
          type: 'verifyemail',
        },
        { headers: { ...(this.recaptchaResponse || {}) } },
      );
      if (res?.data?.success) {
        Toast({ type: 'success', message: 'Email Sent' });
        this.showEmailPopup = false;
        this.allowEmailChange = false;
      } else {
        Toast({ type: 'error', message: 'Oops, something went wrong. Please try again.' });
      }
    } catch (err) {
      if (err.response?.data && this.recaptchaRef.current?.onError) {
        this.recaptchaRef.current.onError(err.response.data).catch((e) => {
          Toast({
            type: 'error',
            message: e?.message || 'Oops, something went wrong. Please try again.',
          });
        });
      } else {
        Toast({ type: 'error', message: 'Oops, something went wrong. Please try again.' });
      }
    }
  };

  onToken = (recaptchaResponse) => {
    this.recaptchaResponse = recaptchaResponse || null;
  };

  updateEmail = async (values) => {
    const { email, password } = values;
    try {
      await RefrensBasicApi(this.store.auth.email, password)
        .patch('/users/me', { email }, { headers: { ...(this.recaptchaResponse || {}) } })
        .then(() => {
          this.store.auth.email = email;
          this.showEmailPopup = false;
          this.allowEmailChange = false;
          trackEvent('User', 'EmailUpdate', 'EmailUpdate', {}, this.user.email);
          Toast({
            type: 'success',
            message: `Success! A verification email has been sent on the email ${email}`,
          });
        });
    } catch (err) {
      const errMessage = err?.response?.data?.message;
      if (err.response?.data && this.recaptchaRef.current?.onError) {
        this.recaptchaRef.current.onError(err.response.data).catch((e) => {
          Toast({
            type: 'error',
            message: e?.message || 'Oops, something went wrong. Please try again.',
          });
        });
      } else {
        Toast({ type: 'error', message: 'Oops, something went wrong. Please try again.' });
      }
      if (this.formRef.current?.setErrors) {
        if (err?.response?.status === 401) {
          this.formRef.current.setErrors({ password: 'Invalid Password' });
        } else if (err?.response?.status === 400 && errMessage) {
          this.formRef.current.setErrors({
            email: errMessage,
          });
        }
      }
    }
  };

  render() {
    if (!this.store.auth) {
      return <></>;
    }
    const { force, hideAlert, onEmailPopupClose } = this.props;
    let showAlert = false;
    if (!this.store.auth.emailVerified && !hideAlert) {
      const createdAt = moment(this.store.auth.createdAt);
      showAlert = force || createdAt.add(1, 'days').isBefore(moment());
    }
    if (this.store.auth.isEmailBlocked && !hideAlert) {
      showAlert = true;
    }

    const formikBag = {
      innerRef: this.formRef,
      initialValues: this.user,
      validationSchema: this.schema,
      onSubmit: this.updateEmail,
    };

    return (
      <>
        {!!showAlert && (
          <Alert
            variant='warning'
            centered
            message={
              <AlertText>
                {this.store.auth.isEmailBlocked
                  ? 'Your email is currently blocked and will no longer be used to send emails.'
                  : 'Verify your email from the verification email you received from Refrens.'}
                <Typography
                  variant='secondary'
                  underline
                  onClick={() => {
                    this.showEmailPopup = true;
                  }}
                >
                  {this.store.auth.isEmailBlocked
                    ? 'Re-verify your email to resume sending >'
                    : 'Send me the email again.'}
                </Typography>
              </AlertText>
            }
          />
        )}

        <Modal
          open={this.showEmailPopup}
          centered
          header={this.allowEmailChange ? 'Update Email' : 'Resend Verification Link'}
          onCancel={() => {
            this.showEmailPopup = false;
            this.allowEmailChange = false;
            if (onEmailPopupClose) {
              onEmailPopupClose();
            }
          }}
          okText='Update Email'
          footer={null}
          maskClosable={false}
          zIndex={10000}
        >
          {!this.allowEmailChange && (
            <BodyWrapper>
              <Typography inline size='label'>
                We will send a verification link to your email at
                <Nbsp />
                <Typography inline bold size='label'>
                  {this.user.email}
                </Typography>
                .
              </Typography>
              <Recaptcha ref={this.recaptchaRef} onChange={this.onToken} />
              <ButtonContainer>
                <Button variant='secondary' onClick={this.resendVerification}>
                  Send Link
                </Button>
                <Button
                  variant='neutral'
                  nature='outlined'
                  onClick={() => {
                    this.allowEmailChange = true;
                  }}
                >
                  Update Email
                </Button>
              </ButtonContainer>
            </BodyWrapper>
          )}

          {this.allowEmailChange && (
            <DiscoForm formik={formikBag}>
              <FormBodyWrapper>
                <DiscoForm.Input required name='email' label='Email' outlined />
                <DiscoForm.Password
                  required
                  name='password'
                  label='Password'
                  outlined
                  visibilityToggle
                />
                <DiscoForm.Error />
                <Recaptcha ref={this.recaptchaRef} onChange={this.onToken} pageName='updateEmail' />
                <ButtonContainer>
                  <DiscoForm.Button type='submit' variant='secondary'>
                    Update Email
                  </DiscoForm.Button>
                  <Button
                    nature='outlined'
                    variant='neutral'
                    onClick={() => {
                      this.allowEmailChange = false;
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonContainer>
              </FormBodyWrapper>
            </DiscoForm>
          )}
        </Modal>
      </>
    );
  }
}

EmailVerificatonPrompt.defaultProps = {
  force: false,
  showEmailPopup: false,
  hideAlert: false,
};

export default inject('store')(observer(EmailVerificatonPrompt));
